<template>
  <div class="body-1">
    <v-card>
      <v-card-title>Register New User</v-card-title>
      <v-card-text>
        <v-alert v-if="errorSavingUser != undefined" type="error">{{errorSavingUser}}</v-alert>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="firstName"
              label="First Name"
              required
              :error-messages="errorSavingUserList.firstName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="lastName"
              label="Last Name"
              required
              :error-messages="errorSavingUserList.lastName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              :error-messages="errorSavingUserList.departmentName"
              v-model="departmentName"
              label="Department Name"
              required
            ></v-text-field>
            <v-text-field
              :error-messages="errorSavingUserList.emailAddress"
              v-model="emailAddress"
              label="Email Address"
              required
            ></v-text-field>
            <v-text-field
              :error-messages="errorSavingUserList.phoneNumber"
              v-model="phoneNumber"
              label="Phone Number"
              required
            ></v-text-field>
            <v-text-field
              :error-messages="errorSavingUserList.password"
              v-model="password"
              label="Password"
              type="password"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :error-messages="errorSavingUserList.address1"
              v-model="address1"
              label="Address 1"
              required
            ></v-text-field>
            <v-text-field
              :error-messages="errorSavingUserList.address2"
              v-model="address2"
              label="Address 2"
            ></v-text-field>
            <v-text-field
              :error-messages="errorSavingUserList.city"
              v-model="city"
              label="City"
              required
            ></v-text-field>
            <v-text-field
              :error-messages="errorSavingUserList.state"
              v-model="state"
              label="State"
              required
            ></v-text-field>
            <v-text-field
              :error-messages="errorSavingUserList.zipCode"
              v-model="zipCode"
              placeholder="Zip Code"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save" :disabled="isSavingUser">Save</v-btn>
        <v-btn @click="cancel" :disabled="isSavingUser" text>Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      valid: true,
      emailAddress: "",
      phoneNumber: "",
      password: "",
      firstName: "",
      lastName: "",
      departmentName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: ""
    };
  },
  computed: {
    ...mapState({
      isSavingUser: state => state.admin.isSavingUser,
      errorSavingUser: state => state.admin.errorSavingUser,
      errorSavingUserList: state => state.admin.errorSavingUserList,
      forceRedirect: state => state.admin.hasSavedUserRedirect
    })
  },
  methods: {
    ...mapActions(["newAdminUser"]),
    save() {
      var newUser = {
        emailAddress: this.emailAddress,
        phoneNumber: this.phoneNumber,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        departmentName: this.departmentName,
        address1: this.address1,
        address2: this.address2,
        city: this.city,
        state: this.state,
        zipCode: this.zipCode
      };
      this.newAdminUser(newUser);
    },
    cancel() {
      this.$router.push({ name: "adminUserList" });
    }
  },
  watch: {
    forceRedirect() {
      this.$router.push({ name: "adminUserList" });
    }
  }
};
</script>

<style>
</style>